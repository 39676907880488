import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for session management
const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const [clientSessionStarted, setClientSessionStarted] = useState(false);
  const [adminSessionStarted, setAdminSessionStarted] = useState(false);
  const [cylinders, setCylinders] = useState(Array(3).fill(null));
  const [consentGiven, setConsentGiven] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  const CLIENT_SESSION_EXPIRY_HOURS = 24;
  const ADMIN_SESSION_EXPIRY_MINUTES = 60;

  // UUID generator for client tokens
  const generateClientToken = () => {
    return 'client_' + Math.random().toString(36).substr(2, 9);
  };

  const startSession = () => {
    const now = new Date();
    const expiryTime = now.getTime() + CLIENT_SESSION_EXPIRY_HOURS * 60 * 60 * 1000;
    setClientSessionStarted(true);
    setConsentGiven(true);
    const newClientToken = generateClientToken(); // Generate token once
    setToken(newClientToken); // Set token in state
    localStorage.setItem('clientToken', newClientToken); // Store the same token in local storage

    localStorage.setItem(
      'sessionData',
      JSON.stringify({ cylinders, consentGiven: true, sessionStarted: true, expiryTime })
    );
  };

  const resetClientSession = () => {
    setCylinders(Array(3).fill(null));
    setClientSessionStarted(false);
    setConsentGiven(false);
  };

  const resetAdminSession = () => {
    setToken(null);
    setUser(null);
    setAdminSessionStarted(false);
    localStorage.removeItem('adminToken');
    localStorage.removeItem('adminTokenExpiry');
  };

  const login = (token, userData) => {
    setToken(token);
    setUser(userData);
    const now = new Date();
    const expiryTime = now.getTime() + ADMIN_SESSION_EXPIRY_MINUTES * 60 * 1000;
    localStorage.setItem('adminToken', token);
    localStorage.setItem('adminTokenExpiry', expiryTime.toString());
    setAdminSessionStarted(true);
  };

  // Single useEffect to restore sessions
  useEffect(() => {
    const savedSession = JSON.parse(localStorage.getItem('sessionData'));
    const clientToken = localStorage.getItem('clientToken');
    const adminToken = localStorage.getItem('adminToken');
    const adminTokenExpiry = localStorage.getItem('adminTokenExpiry');
    const now = new Date().getTime();

    // Restore client session if valid
    if (savedSession) {
      if (now < savedSession.expiryTime && savedSession.consentGiven) {
        setCylinders(savedSession.cylinders || []);
        setClientSessionStarted(true);
        setConsentGiven(savedSession.consentGiven);
        setToken(clientToken);
      } else {
        localStorage.removeItem('sessionData');
        resetClientSession();
      }
    } else {
      resetClientSession();
    }

    // Restore admin session if valid
    if (adminToken && adminTokenExpiry) {
      if (now < parseInt(adminTokenExpiry, 10)) {
        setToken(adminToken);
        setAdminSessionStarted(true);
      } else {
        resetAdminSession();
      }
    } else {
      resetAdminSession();
    }

    setLoading(false);
  }, []);

  // Update client sessionData whenever cylinders, consentGiven, or clientSessionStarted change
  useEffect(() => {
    if (consentGiven) {
      const now = new Date();
      const expiryTime = now.getTime() + CLIENT_SESSION_EXPIRY_HOURS * 60 * 60 * 1000;
      localStorage.setItem(
        'sessionData',
        JSON.stringify({ cylinders, consentGiven, sessionStarted: clientSessionStarted, expiryTime })
      );
    }
  }, [cylinders, consentGiven, clientSessionStarted]);

  // Update admin token expiry whenever token changes
  useEffect(() => {
    if (token && adminSessionStarted) {
      const now = new Date();
      const expiryTime = now.getTime() + ADMIN_SESSION_EXPIRY_MINUTES * 60 * 1000;
      localStorage.setItem('adminToken', token);
      localStorage.setItem('adminTokenExpiry', expiryTime.toString());
    }
  }, [token, adminSessionStarted]);

  const addCylinder = (item) => {
    setCylinders((prev) => {
      const nextCylinders = [...prev];
      const existingItemIndex = nextCylinders.findIndex(
        (cylinder) =>
          cylinder &&
          cylinder._id === item._id &&
          cylinder.selectedSize.size === item.selectedSize.size
      );

      if (existingItemIndex !== -1) {
        const existingCylinder = nextCylinders[existingItemIndex];
        existingCylinder.quantity = (existingCylinder.quantity || 1) + 1;
      } else {
        const emptyIndex = nextCylinders.lastIndexOf(null);
        if (emptyIndex !== -1) {
          nextCylinders[emptyIndex] = { ...item, quantity: 1 };
        } else {
          nextCylinders.push({ ...item, quantity: 1 });
        }
      }

      return nextCylinders;
    });
  };

  const logout = () => {
    resetAdminSession();
    if (clientSessionStarted) {
      return;
    }
    resetClientSession();
  };

  return (
    <SessionContext.Provider
      value={{
        clientSessionStarted,
        cylinders,
        setCylinders,
        startSession,
        addCylinder,
        setConsentGiven,
        consentGiven,
        loading,
        adminSessionStarted,
        login,
        logout,
        token,
        user,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
