// client/src/components/PaymentForm.js

import React, { useState, useContext, useEffect, useRef } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useSession } from "../context/SessionContext";
import lockImage from "../assets/lock.png";
import "./PaymentForm.css";
import { ModalContext } from "../context/ModalContext";
import postNordLogo from "../assets/postnord-logo.png";
import dhlLogo from "../assets/Dhl-logo.png";

const PaymentForm = ({
  billingDetails,
  quantities,
  setPaymentStatus,
  setPaymentStatusMessage,
  cylinders: propCylinders,
  shippingOptions,
  selectedShippingOption,
  setSelectedShippingOption,
}) => {
  const { cylinders: contextCylinders } = useSession();
  const cylinders = propCylinders || contextCylinders;

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const paypalRef = useRef();

  const totalItems = quantities.reduce((total, qty) => total + qty, 0);
  const { togglePrivacyModal, toggleTermsModal } = useContext(ModalContext);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  // Recalculate amount when selectedShippingOption changes
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const shippingCost = selectedShippingOption ? selectedShippingOption.cost : 0;
    const totalPrice = cylinders.reduce((total, item, index) => {
      if (item && quantities[index]) {
        const price = parseFloat(item.selectedSize.price);
        const quantity = parseInt(quantities[index], 10);
        if (isNaN(price) || isNaN(quantity)) {
          return total; // Skip this item if price or quantity is invalid
        }
        return total + price * quantity;
      }
      return total;
    }, 0);

    const newAmount = Math.round((totalPrice + shippingCost) * 100);
    setAmount(newAmount);
  }, [selectedShippingOption, cylinders, quantities]);

  // PaymentForm.js

  const createOrderInSystem = async () => {
    try {
      // First, define validCylinders and validQuantities
      const validCylinders = [];
      const validQuantities = [];
      cylinders.forEach((cylinder, index) => {
        if (cylinder !== null && cylinder !== undefined) {
          validCylinders.push(cylinder);
          validQuantities.push(quantities[index]);
        }
      });

      if (validCylinders.length === 0) {
        throw new Error("No valid products in the cart.");
      }

      // Now you can use them
      // Proceed with the fetch request
      const orderResponse = await fetch("/api/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerName: billingDetails.name,
          customerEmail: billingDetails.email,
          address: `${billingDetails.address}, ${billingDetails.city}, ${billingDetails.postalCode}`,
          products: validCylinders.map((cylinder, index) => ({
            productId: cylinder._id,
            name: `${cylinder.brand} ${cylinder.model}`,
            quantity: validQuantities[index],
            size: cylinder.selectedSize.size,
            price: cylinder.selectedSize.price,
          })),
          shippingMethod: selectedShippingOption.name,
          shippingCost: selectedShippingOption.cost,
          estimatedDelivery: selectedShippingOption.estimatedDelivery,
          selectedShippingOption: selectedShippingOption.id,
        }),
      });

      if (!orderResponse.ok) {
        throw new Error("Failed to create order.");
      }

      const orderData = await orderResponse.json();
      return orderData.order._id; // Extract and return the order ID
    } catch (error) {
      console.error("Order Creation Error:", error);
      throw error;
    }
  };

  const handlePaymentSuccess = async (orderId) => {
    try {
      const bookShipmentResponse = await fetch(
        `/api/orders/${orderId}/book-shipment`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!bookShipmentResponse.ok) {
        throw new Error("Shipment booking failed");
      }

      const shipmentData = await bookShipmentResponse.json();
    } catch (error) {
      console.error("Error booking shipment:", error);
    }
  };

  // ** Load PayPal SDK Dynamically **
  useEffect(() => {
    const loadPayPalScript = async () => {
      if (paymentMethod === "paypal" && agreedToTerms) {
        if (window.paypal) {
          // PayPal SDK already loaded
          return;
        }
        try {
          const res = await fetch("/api/paypal/config");
          const data = await res.json();
          const script = document.createElement("script");
          script.src = `https://www.paypal.com/sdk/js?client-id=${data.clientId}&currency=EUR`;
          script.async = true;
          script.onload = () => {
            window.paypal
              .Buttons({
                createOrder: async () => {
                  const response = await fetch("/api/paypal/create-order", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      amount: parseFloat((amount / 100).toFixed(2)),
                    }),
                  });
                  const orderData = await response.json();
                  return orderData.id;
                },
                onApprove: async (data) => {
                  try {
                    const response = await fetch("/api/paypal/capture-order", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({ orderID: data.orderID }),
                    });
                    const captureData = await response.json();

                    if (!response.ok) {
                      throw new Error(
                        captureData.error || "Failed to capture PayPal order."
                      );
                    }

                    // Proceed to create the order
                    const orderId = await createOrderInSystem();
                    await handlePaymentSuccess(orderId);

                    setPaymentStatus("success");
                    setPaymentStatusMessage(
                      "Order and payment successful! You should receive an email confirmation. (Check your spam folder if you don’t see it.)"
                    );
                  } catch (err) {
                    console.error("Payment or Order Error:", err);
                    setPaymentStatus("error");
                    setPaymentStatusMessage(
                      "An error occurred during checkout. Please refresh and retry, and if the issue persists, please contact support@ttscent.com"
                    );
                  }
                },
                onError: (err) => {
                  console.error("PayPal Checkout onError", err);
                  setPaymentStatus("error");
                  setPaymentStatusMessage(
                    "An error occurred during checkout. Please try again."
                  );
                },
              })
              .render(paypalRef.current);
          };
          document.body.appendChild(script);
        } catch (error) {
          console.error("Error loading PayPal SDK:", error);
          setPaymentStatus("error");
          setPaymentStatusMessage(
            "An error occurred while loading the PayPal SDK. Please try again."
          );
        }
      }
    };

    loadPayPalScript();
  }, [
    paymentMethod,
    agreedToTerms,
    amount,
    billingDetails,
    quantities,
    cylinders,
  ]);

  // Handle Stripe payment
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      setPaymentStatus("error");
      setPaymentStatusMessage(
        "An error occurred during checkout. Please refresh and retry, and if the issue persists, please contact support@ttscent.com"
      );
      return;
    }

    if (!agreedToTerms) {
      setLoading(false);
      setPaymentStatus("error");
      setPaymentStatusMessage(
        "Please agree to the Terms and Conditions and Privacy Policy before proceeding."
      );
      return;
    }

    try {
      // Ensure amount is an integer
      const roundedAmount = Math.round(amount);
      console.log("Rounded Amount (in cents):", roundedAmount); // Debugging

      const paymentIntentResponse = await fetch("/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: roundedAmount }),
      });

      // Handle non-OK responses
      if (!paymentIntentResponse.ok) {
        const errorData = await paymentIntentResponse.json();
        throw new Error(errorData.error || "Failed to create payment intent.");
      }

      const paymentIntentData = await paymentIntentResponse.json();
      const clientSecret = paymentIntentData.clientSecret;

      if (!clientSecret) {
        throw new Error("No client secret returned");
      }

      const cardElement = elements.getElement(CardElement);

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: billingDetails.name,
            address: {
              line1: billingDetails.address,
              city: billingDetails.city,
              postal_code: billingDetails.postalCode,
            },
            email: billingDetails.email,
          },
        },
      });

      if (error) {
        throw error;
      }

      // Proceed to create the order
      const orderId = await createOrderInSystem();
      await handlePaymentSuccess(orderId);

      setPaymentStatus("success");
      setPaymentStatusMessage(
        "Order and payment successful! You should receive an email confirmation. (Check your spam folder if you don’t see it.)"
      );
    } catch (err) {
      console.error("Payment or Order Error:", err);
      setPaymentStatus("error");
      setPaymentStatusMessage(
        "An error occurred during checkout. Please refresh and retry, and if the issue persists, please contact support@ttscent.com"
      );
    }

    setLoading(false);
  };

  // Handle shipping option change
  const handleSelectOption = (e) => {
    const selectedIndex = e.target.value;
    if (selectedIndex !== "") {
      const option = shippingOptions[selectedIndex];
      setSelectedShippingOption(option);
    } else {
      setSelectedShippingOption(null);
    }
  };

  const getShippingLogo = () => {
    const name = selectedShippingOption?.name.toLowerCase();
    if (name?.includes("postnord") || name?.includes("varubrev")) {
      return "postnord";
    } else if (name?.includes("dhl")) {
      return "dhl";
    }
    return "both";
  };


  return (
    <div
      className="payment-form-container max-w-md mx-auto p-6 border border-gray-300 rounded-lg bg-white shadow-md"
      style={{ color: "black" }}
    >
      <div className="order-summary mb-4 p-4 border border-gray-300 rounded-md">
        <h3 className="font-bold text-lg mb-2">Order Summary</h3>

        {cylinders.map(
          (cylinder, index) =>
            cylinder && (
              <div
                key={index}
                className={`order-row flex items-center justify-between py-2 px-3 ${index % 2 === 0 ? "bg-white" : "bg-gray-100"
                  }`}
              >
                {/* Add the product image here */}
                <img
                  src={`/uploads/${cylinder.gender}/${cylinder.brand
                    .replace(/\s+/g, "_")
                    .toLowerCase()}/${cylinder.model
                      .replace(/\s+/g, "_")
                      .toLowerCase()}/${cylinder.images[0]}`}
                  alt={`${cylinder.brand} ${cylinder.model}`}
                  className="w-10 h-10 object-cover mr-2 rounded-md"
                />

                <div className="flex flex-col flex-grow">
                  <p className="font-semibold">
                    {cylinder.brand.replace(/_/g, " ")} {cylinder.model.replace(/_/g, " ")} (
                    {cylinder.selectedSize.size}ml)
                  </p>
                </div>

                <p>€{cylinder.selectedSize.price} x {quantities[index]}</p>
              </div>
            )
        )}

        <div className="order-totals mt-4 p-4 bg-gray-50 rounded-md">
          <p>
            Total Items: <span className="font-semibold">{totalItems}</span>
          </p>
          <p>
            Subtotal:{" "}
            <span className="font-semibold">
              €
              {cylinders
                .reduce((total, item, index) => {
                  if (item && quantities[index]) {
                    const price = parseFloat(item.selectedSize.price);
                    const quantity = parseInt(quantities[index], 10);
                    if (isNaN(price) || isNaN(quantity)) {
                      return total; // Skip this item if price or quantity is invalid
                    }
                    return total + price * quantity;
                  }
                  return total;
                }, 0)
                .toFixed(2)}
            </span>
          </p>
          {shippingOptions && shippingOptions.length > 0 && (
            <>
              <div className="mt-4">
                <h4 className="font-bold mb-2">Select Shipping Option:</h4>
                <select
                  onChange={handleSelectOption}
                  value={shippingOptions.findIndex(
                    (option) => option === selectedShippingOption
                  )}
                  className="w-full p-2 border border-gray-300 rounded-md mb-4"
                >
                  {shippingOptions.map((option, index) => (
                    <option key={index} value={index}>
                      {option.name} - €{option.cost.toFixed(2)} (Est. delivery:{" "}
                      {option.estimatedDelivery})
                    </option>
                  ))}
                </select>

                <div className="flex flex-col items-center mb-4 logo-container">
                  {getShippingLogo() === "postnord" && (
                    <img src={postNordLogo} alt="PostNord" className="logo postnord-logo" />
                  )}
                  {getShippingLogo() === "dhl" && (
                    <img src={dhlLogo} alt="DHL" className="logo dhl-logo" />
                  )}
                  {getShippingLogo() === "both" && (
                    <>
                      <img src={postNordLogo} alt="PostNord" className="logo postnord-logo" />
                      <img src={dhlLogo} alt="DHL" className="logo dhl-logo" />
                    </>
                  )}
                </div>

              </div>
              <p>
                Shipping Cost:{" "}
                <span className="font-semibold">
                  €{selectedShippingOption.cost.toFixed(2)}
                </span>
              </p>
            </>
          )}
          <p>
            Total Cost:{" "}
            <span className="font-bold text-lg">
              €{(amount / 100).toFixed(2)}
            </span>
          </p>
        </div>
      </div>

      <div className="payment-method-selection mb-4">
        <h3 className="font-bold mb-2">Select Payment Method:</h3>
        <label className="mr-4">
          <input
            type="radio"
            name="paymentMethod"
            value="stripe"
            checked={paymentMethod === "stripe"}
            onChange={() => setPaymentMethod("stripe")}
          />
          Credit/Debit Card (Stripe)
        </label>
        <label>
          <input
            type="radio"
            name="paymentMethod"
            value="paypal"
            checked={paymentMethod === "paypal"}
            onChange={() => setPaymentMethod("paypal")}
          />
          PayPal
        </label>
      </div>

      <input
        type="checkbox"
        id="terms"
        checked={agreedToTerms}
        onChange={() => setAgreedToTerms(!agreedToTerms)}
        className="mr-2"
      />
      <label htmlFor="terms">
        I agree to the{" "}
        <span
          className="text-blue-500 cursor-pointer"
          onClick={toggleTermsModal}
        >
          Terms and Conditions
        </span>{" "}
        and acknowledge the{" "}
        <span
          className="text-blue-500 cursor-pointer"
          onClick={togglePrivacyModal}
        >
          Privacy Policy
        </span>
        .
      </label>

      <div className="mb-4 p-2 bg-green-100 border border-green-400 rounded-md flex items-center security-message">
        <img src={lockImage} alt="Secure" className="h-5 w-4 mr-2" />
        <p className="text-green-700 text-sm">
          Your payment is secure and encrypted.
        </p>
      </div>

      {paymentMethod === "stripe" && (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
                hidePostalCode: true,
              }}
              className="p-2 border border-gray-300 rounded-md"
            />
          </div>

          <button
            type="submit"
            className={`w-full p-2 rounded-md text-white ${!stripe || loading || !agreedToTerms
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
              }`}
            disabled={!stripe || loading || !agreedToTerms}
          >
            {loading ? "Processing..." : "Pay Securely"}
          </button>
        </form>
      )}

      {paymentMethod === "paypal" && !agreedToTerms && (
        <p className="text-red-500 mt-4">
          Please agree to the Terms and Conditions and Privacy Policy to proceed
          with payment.
        </p>
      )}

      {paymentMethod === "paypal" && agreedToTerms && (
        <div ref={paypalRef} className="paypal-button-container"></div>
      )}
    </div>
  );
};

export default PaymentForm;
